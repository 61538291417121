// import { HEADING_VARIANT_PAGE_TITLE } from '../../../inc/constants'

export const Heading = {
    baseStyle: {
        fontWeight: 500,
        lineHeight: 8,
        mb: 6,
    },
    // Note: when adding variants to this list, add a constant with the variant
    // name to ../../inc/constants, and then use the constant as the link
    // variant prop.
    // variants: {
    //     [HEADING_VARIANT_PAGE_TITLE]: {
    //         textColor: 'kelsonGrayscale.50',
    //         maxWidth: 'container.lg',
    //         textAlign: { base: 'center', lg: 'right' },
    //         fontWeight: 520,
    //         lineHeight: '1em',
    //         fontStyle: 'normal',
    //         textShadow: '0 0 10px #142E3F',
    //         backdropFilter: 'blur(1px)',
    //         p: 4,
    //         textTransform: 'none',
    //         fontSize: { base: '5xl', md: '7xl', lg: '8xl', xl: '9xl' },
    //     },
    //     pageTitleAlt: {
    //         textColor: 'kelsonGrayscale.800',
    //         textTransform: 'none',
    //         fontStyle: 'normal',
    //         maxWidth: 'container.xs',
    //         px: 8,
    //         mb: 32,
    //         textAlign: { base: 'center', lg: 'right' },
    //         fontSize: { base: '2xl', lg: '4xl' },
    //         lineHeight: '1.5em',
    //     },
    //     blockHeading: {
    //         mb: 4,
    //     },
    //     blockSubheading: {
    //         fontStyle: 'italic',
    //         fontWeight: 'normal',
    //     },
    // },
}

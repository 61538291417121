import { extendTheme } from '@chakra-ui/react'
import { List, ListConstants } from './core/List'
import { Link, LinkConstants } from './core/Link'
import { colors } from './foundations/colors'
import { sizes } from './foundations/sizes'
import { Heading } from './core/Heading'
import { Text } from './core/Text'
// import { Button } from './core/Button'

const fontFamilySerif =
    'CormorantVariable, "Apple Garamond", "Baskerville", "Times New Roman", "Droid Serif", "Times","Source Serif Pro", serif'
const fontFamilySansSerif =
    'RalewayVariable, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const extensions = {
    // ...globalThemeStyles,
    fonts: {
        heading: fontFamilySerif,
        body: fontFamilySansSerif,
        serif: fontFamilySerif,
        sans: fontFamilySansSerif,
    },
    // Foundational overrides.
    sizes,
    // space: spacing,
    colors,
    // shadows: shadows,
    // ...typography,
    // // Component overrides.
    components: {
        List,
        Heading,
        Link,
        Text,
        // Button,
        // Divider: Divider,
        // Modal: Modal,
    },
    // direction,
    // config,
    styles: {
        global: {
            body: {
                fontSize: 'xl',
                background: 'kelsonGrayscale.50',
            },
        },
    },
    constants: {
        ...ListConstants,
        ...LinkConstants,
    },
}

export default extendTheme(extensions)

// @see: https://chakra-ui.com/docs/theming/theme#colors
export const colors = {
    oriGrayscale: {
        50: '#eaeaea',
        100: '#d0d0d0' /* logo */,
        200: '#a9a9a9',
        300: '#868686',
        400: '#6c6c6c',
        500: '#525252',
        600: '#4b4b4b',
        700: '#414141',
        800: '#383838',
        900: '#272727' /* logo */,
    },
    oriOrange: {
        50: '#fcece2',
        100: '#f6d1b7',
        200: '#f1b287',
        300: '#eb9356',
        400: '#e67b32',
        500: '#e2640e' /* logo */,
        600: '#df5c0c',
        700: '#da520a',
        800: '#d64808',
        900: '#cf3604',
    },
    oriBlue: {
        50: '#e1e7ea',
        100: '#b3c4cb',
        200: '#819ca9',
        300: '#4e7486',
        400: '#28576c',
        500: '#023952',
        600: '#02334b',
        700: '#012c41',
        800: '#012438',
        900: '#011728',
    },
    oriTeal: {
        50: '#e1ecec',
        100: '#b3d0cf',
        200: '#81b1af',
        300: '#4f928f',
        400: '#297a77',
        500: '#03635f',
        600: '#035b57',
        700: '#02514d',
        800: '#024743',
        900: '#013532',
    },
    // oriGreen: {
    //     50: '#e1eceb',
    //     100: '#b3d0cd',
    //     200: '#81b1ab',
    //     300: '#4f9289',
    //     400: '#297a70',
    //     500: '#036357',
    //     600: '#035b4f',
    //     700: '#025146',
    //     800: '#02473c',
    //     900: '#01352c',
    // },
}

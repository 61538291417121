// @see: https://chakra-ui.com/docs/navigation/link

export const LinkConstants = {
    LINK_BUTTON_OUTLINE: 'LINK_BUTTON_OUTLINE',
    LINK_INHERIT: 'LINK_INHERIT',
}

const { LINK_INHERIT, LINK_BUTTON_OUTLINE } = LinkConstants

export const Link = {
    baseStyle: {
        fontWeight: 700,
        textColor: 'inherit',
        transition: '0.3s all',
        textDecoration: 'none',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        _after: {
            content: '""',
            backgroundColor: 'oriOrange.500',
            width: '0%',
            height: '1px',
            transition: 'width 0.3s ease-in-out',
        },
        _hover: {
            textDecoration: 'none',
            textColor: 'inherit',
            '&:after': {
                width: '100%',
            },
        },
        _focus: {
            outline: 'none',
        },
    },
    // Note: when adding variants to this list, add a constant with the variant
    // name to ../../inc/constants, and then use the constant as the link
    // variant prop.
    variants: {
        [LINK_BUTTON_OUTLINE]: {
            border: '1px solid',
            borderColor: 'oriBlue.200',
            bg: 'transparent',
            color: 'oriBlue.200',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 4,
            px: 8,
            textAlign: 'center',
            fontWeight: 400,
            _hover: {
                bg: 'oriBlue.100',
                color: 'oriBlue.900',
                fontWeight: 400,
                '&:after': {
                    width: '0%',
                },
            },
            transition: '0.3s all',
        },
        [LINK_INHERIT]: {
            textColor: 'inherit',
            fontFamily: 'inherit',
            _hover: {
                textColor: 'inherit',
                textDecoration: 'inherit',
            },
            _after: {
                height: 0,
                mt: 0,
            },
        },
    },
}
